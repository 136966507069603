import { Decimal } from 'decimal.js'

export const ORDER_STATUS = {
    init: {
        name: "init",
        displayName: "订单已生成",
        order: 0,
        color: '#F1A24C',
    },

    toSubmitContract: {
        name: "toSubmitContract",
        displayName: "待上传合同",
        icon: "upload",
        color: '#F1A24C',
        order: 1
    },

    contractApproving: {
        name: "contractApproving",
        displayName: "合同审核中",
        icon: "solution",
        color: '#F1A24C',
        order: 2
    },

    contractApproved: {
        name: "contractApproved",
        displayName: "合同审核通过",
        icon: "check",
        color: '#6FC17C',
        order: 2
    },

    contractRejected: {
        name: "contractRejected",
        displayName: "合同审核被驳回",
        icon: "close",
        color: '#E72222',
        order: 2
    },

    toSubmitPayment: {
        name: "toSubmitPayment",
        displayName: "待上传首付款证明",
        color: '#F1A24C',
        order: 3
    },

    paymentApproving: {
        name: "paymentApproving",
        displayName: "首付款证明审核中",
        icon: "solution",
        color: '#F1A24C',
        order: 4
    },

    paymentApproved: {
        name: "paymentApproved",
        displayName: "首付款证明审核通过",
        icon: "check",
        color: '#6FC17C',
        order: 4
    },

    paymentRejected: {
        name: "paymentRejected",
        displayName: "首付款证明审核被驳回",
        icon: "close",
        color: '#E72222',
        order: 4
    },


    inProduction: {
        name: "inProduction",
        displayName: "生产中",
        icon: "setting",
        color: '#F1A24C',
        order: 5
    },

    toSubmitFinalPayment: {
        name: "toSubmitFinalPayment",
        displayName: "待上传尾款证明",
        color: '#F1A24C',
        order: 6
    },

    finalPaymentApproving: {
        name: "finalPaymentApproving",
        displayName: "尾款证明审核中",
        icon: "solution",
        color: '#F1A24C',
        order: 7
    },

    finalPaymentApproved: {
        name: "finalPaymentApproved",
        displayName: "尾款款证明审核通过",
        icon: "check",
        color: '#6FC17C',
        order: 7
    },

    finalPaymentRejected: {
        name: "finalPaymentRejected",
        displayName: "尾款证明审核被驳回",
        icon: "close",
        color: '#E72222',
        order: 7
    },

    toDeliver: {
        name: "toDeliver",
        displayName: "待发货",
        color: '#F1A24C',
        order: 8
    },

    finished: {
        name: "finished",
        displayName: "已完成",
        color: '#6FC17C',
        order: 9,
        icon: "check",
    },

    closed: {
        name: "closed",
        displayName: "已关闭",
        color: '#E72222',
        order: 9,
        icon: "close",
    }
}

export const PURCHASE_TYPE = {
    only_shell: {
        name: 'only_shell',
        displayName: '仅壳价',
        title: '壳价',
        component: '壳价'
    },
    give_engine: {
        name: 'give_engine',
        displayName: '送电机（电缆及配件）',
        title: '电缆及配件价',
        component: '壳价+电缆及配件价'
    },
    contains_engine: {
        name: 'contains_engine',
        displayName: '含电机',
        title: '电机价',
        component: '壳价+电缆及配件价+电机价'
    }
}

export const EQUIPMENT_TYPE = {
    fanBox: {
        name: 'fanBox',
        displayName: '风机箱'
    },
    roundFan: {
        name: 'roundFan',
        displayName: '圆风机'
    }
}

export const ENGINE_LOCATION = {
    left: {
        name: 'left',
        displayName: '左置'
    },
    right: {
        name: 'right',
        displayName: '右置'
    },
    up: {
        name: 'up',
        displayName: '上置'
    },
    behind: {
        name: 'behind',
        displayName: '后置'
    },
    inner: {
        name: 'inner',
        displayName: '内置'
    }
}

export const WIND_DIRECTION = {
    rightInUpOut: {
        name: 'rightInUpOut',
        displayName: '右进上出'
    },
    rightInBehindOut: {
        name: 'rightInBehindOut',
        displayName: '右进后出'
    },
    leftInUpOut: {
        name: 'leftInUpOut',
        displayName: '左进上出'
    },
    leftInBehindOut: {
        name: 'leftInBehindOut',
        displayName: '左进后出'
    },
    frontInBehindOut: {
        name: 'frontInBehindOut',
        displayName: '前进后出'
    },
    frontInUpOut: {
        name: 'frontInUpOut',
        displayName: '前进上出'
    },
    upInBehindOut: {
        name: 'upInBehindOut',
        displayName: '上进后出'
    },
}

export const SHELL_THICKNESS = [
    '2.0', '2.5', '3.0'
]


export const DOWN_PAYMENT_PERCENTAGE = [20, 30, 40, 50, 60, 70, 80, 90, 100]

export function centToYuan(price) {
    return (price / 100).toFixed(2)
}

export function centToYuanRound(price) {
    return Math.round(centToYuan(price))
    // return new Decimal(price).divToInt(100)
}

/**
 * 将单位为分的价格转化为变成元后取整，但仍然返回分
 * e.g. 12345 -> 12300, 12356 -> 12400
 * @param price
 */
export function centToRound(price) {
    return Math.round(price / 100) * 100
}

/**
 * 根据总价和首付款比例计算首付款
 * 算法：总价 * 首付款比例 后取整，取整规则：
 *  总价 * 首付款比例后的第二位 >= 5，则第二位设置为5，后面全部是0
 *  总价 * 首付款比例后的第二位 < 5，则第二位(包括)到后面全部是0
 * @param totalPrice 总价，单位为分
 * @param downPaymentPercentage 首付款比例
 * @return 首付款，结果为整数（首付款比例为 100 时与总价相同），单位：元
 */
export function getDownPaymentPrice(totalPrice, downPaymentPercentage) {
    if (downPaymentPercentage >= 100) {
        return centToYuanRound(totalPrice)
    }
    // 取整前的价格，单位是元
    const downPaymentPriceBeforeRound = centToYuanRound(Decimal.mul(totalPrice, downPaymentPercentage).div(100));
    // 只有两位数以上才遵循取整算法
    if (downPaymentPriceBeforeRound <= 10) {
        return downPaymentPriceBeforeRound
    }

    // 获取位数(有几位)
    const digit = downPaymentPriceBeforeRound.toString().length
    const mask = getNumberByDigits(digit - 2)

    // 获取第一、二位数
    const firstDigit = downPaymentPriceBeforeRound.toString()[0];
    const secondDigit = downPaymentPriceBeforeRound.toString()[1];

    return firstDigit * mask * 10 + (secondDigit >= 5 ? 5 * mask : 0)
}


/**
 * 获得 尾款信息
 * @param totalPrice
 * @param downPaymentPercentage
 * @returns {string}
 */
export function getFinalPaymentPrice(totalPrice, downPaymentPercentage) {
    return Decimal.sub(centToYuan(totalPrice), getDownPaymentPrice(totalPrice, downPaymentPercentage)).toFixed(2)
}

/**
 * 获得 1后面几个零的数，结果为 10, 100, 1000, 10000...
 * @param digits
 * @returns {number}
 */
function getNumberByDigits(digits) {
    return 10 ** (digits);
}


export const AES_PASSWORD = 'factory-be2fe-240323-w7R9xZ'

export const LOCAL_STORAGE_KEY = {
    toRoute: 'toRoute',
    token: 'token',
    currentUser: 'currentUser'
}

export const REGEX = {
    phoneNo: /^1[1-9][0-9]{9}$/,
    usci: /^[0-9A-Z]{18}$/
}

export const TOKEN_SCENE = {
    register: 'register',
    login: 'login',
    forgotPassword: 'forgotPassword'
}

export function logout() {
    localStorage.removeItem(LOCAL_STORAGE_KEY.currentUser)
    localStorage.removeItem(LOCAL_STORAGE_KEY.token)
}
