<template>
  <div class="custom-header">
    <div class="title">
      四川光辰金属制品制造合伙企业（有限合伙）
    </div>
    <div class="menu">
      <a-menu mode="horizontal" :selectedKeys="[selectedItemKey]" @click="click">
        <a-menu-item key="home">
          首页
        </a-menu-item>
        <a-menu-item key="motorList">
          电机列表
        </a-menu-item>
        <a-menu-item key="orderCreate">
          下单
        </a-menu-item>
        <a-menu-item key="orderList">
          订单列表
        </a-menu-item>
        <a-menu-item key="my">
          我的
        </a-menu-item>

      </a-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomHeader",
  created() {
    const path = this.$route.path;
    if (path === '/home') {
      this.selectedItemKey = 'home'
    }

    if (path === '/motor/list') {
      this.selectedItemKey = 'motorList'
    }

    if (path === '/order/create') {
      this.selectedItemKey = 'orderCreate'
    }

    if (path === '/order/list') {
      this.selectedItemKey = 'orderList'
    }

    if (path === '/my') {
      this.selectedItemKey = 'my'
    }

  },
  data() {
    return {
      selectedItemKey: 'home'
    };
  },
  methods: {
    click(value) {
      this.selectedItemKey = value.key
      if (value.key === 'home') {
        this.$router.push("/home");
      }
      if (value.key === 'motorList') {
        this.$router.push("/motor/list");
      }

      if (value.key === 'orderCreate') {
        this.$router.push("/order/create");
      }

      if (value.key === 'orderList') {
        this.$router.push("/order/list");
      }

      if (value.key === 'my') {
        this.$router.push("/my");
      }
    }
  },
  watch: {
    $route(to, from) {
      console.log('to', to, 'from', from)

      if (to.path === '/home') {
        this.selectedItemKey = 'home'
      }
      if (to.path === '/order/create') {
        this.selectedItemKey = 'orderCreate'
      }
      if (to.path === '/order/list') {
        this.selectedItemKey = 'orderList'
      }
      if (to.path === '/my') {
        this.selectedItemKey = 'my'
      }
    }
  }
}
</script>

<style scoped lang="scss">


.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #6cb779;
  border-bottom: 3px solid #6cb779;
}

.ant-menu-horizontal {
  border: none;
  background-color: transparent;
}

.ant-menu-item {
  margin: 0 40px;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.custom-header {
  display: flex;
  padding: 20px 100px;
  width: 100%;
  justify-content: space-between;
  background-color: #1C1E22;
  color: white;

  .title {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
  }

  .menu {

  }
}
</style>
