import axios from 'axios'
import notification from 'ant-design-vue/es/notification'
import {VueAxios} from './axios'
import {LOCAL_STORAGE_KEY} from "@/utils/constants";

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
    if (error.response) {
        const data = error.response.data
        // 从 localstorage 获取 token
        if (error.response.status === 403) {
            notification.error({
                message: 'Forbidden',
                description: data.message
            })
        }
    }
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEY.token)
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    }

    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
    const res = response.data
    if (res.groupCode || res.downloadUrl) {
        return res
    }

    if (res.code !== 200) {
        // 文件类型直接返回
        if (res instanceof Blob) {
            return response
        }
        return Promise.reject(res)
    } else {
        return res
    }
}, errorHandler)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, request)
    }
}

export default request

export {
    installer as VueAxios,
    request as axios
}
