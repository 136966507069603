import Vue from 'vue';
import VueRouter from 'vue-router';
import CustomLayout from "@/components/CustomLayout.vue";
import {isNotNull} from "@/utils/objectUtils";
import {LOCAL_STORAGE_KEY} from "@/utils/constants";
import {queryCurrentUser} from "@/api/user";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: CustomLayout, // 使用布局组件
        redirect: "/home",
        children: [
            {
                path: "/home",
                component: () => import('@/views/home/HomeView.vue'),
                meta: {
                    title: '首页',
                }
            },
            {
                path: "/motor/list",
                component: () => import('@/views/motor/MotorList.vue'),
                meta: {
                    title: '电机列表',
                }
            },
            {
                path: "/order/create",
                component: () => import('@/views/order/OrderCreate.vue'),
                meta: {
                    title: '下单',
                }
            },
            {
                path: "/order/list",
                component: () => import('@/views/order/OrderList.vue'),
                meta: {
                    title: '订单列表',
                }
            },
            {
                path: "/order/detail",
                component: () => import('@/views/order/OrderDetail.vue'),
                meta: {
                    title: '订单详情',
                }
            },
            {
                path: "/my",
                component: () => import('@/views/my/MyView.vue'),
                meta: {
                    title: '我的',
                }
            },
            {
                path: "/modify/password",
                component: () => import('@/views/my/ModifyPassword.vue'),
                meta: {
                    title: '修改密码',
                }
            },
            {
                path: "/login",
                component: () => import('@/views/login/LoginView.vue'),
                meta: {
                    title: '登录',
                }
            },
            {
                path: "/register",
                component: () => import('@/views/register/RegisterView.vue'),
                meta: {
                    title: '注册',
                }
            }
        ]
    }
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    console.log('to', to, 'from', from)

    if (['/home', '/register', '/login'].includes(to.path)) {
        next()
        return
    }

    const currentUser = localStorage.getItem(LOCAL_STORAGE_KEY.currentUser);
    if (isNotNull(currentUser)) {
        queryCurrentUser().then(res => {
            if (res.success) {
                localStorage.setItem(LOCAL_STORAGE_KEY.currentUser, JSON.stringify(res.data))
                next()
            } else {
                next({path: '/login'})
            }
        }).catch(() => {
            next({path: '/login'})
        })
    } else {
        localStorage.setItem(LOCAL_STORAGE_KEY.toRoute, to.fullPath)
        next({path: '/login'})
    }
})

export default router;
