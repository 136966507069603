<template>
  <div class="custom-footer">
  </div>
</template>

<script>
export default {
  name: "CustomFooter"
}
</script>

<style scoped lang="scss">
.custom-footer {

}
</style>
