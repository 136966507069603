<template>
  <div class="custom-layout">
    <CustomHeader></CustomHeader>
    <router-view/>
    <CustomFooter></CustomFooter>
  </div>
</template>

<script>
import CustomFooter from "@/components/CustomFooter.vue";
import CustomHeader from "@/components/CustomHeader.vue";

export default {
  name: "CustomLayout",
  components: {CustomHeader, CustomFooter}
}
</script>


<style lang="scss" scoped>
.custom-layout {

}
</style>
