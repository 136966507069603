import Vue from 'vue'
import App from './App.vue'
import Antd, {message} from 'ant-design-vue';
import { Button } from 'ant-design-vue';

import router from './router/router'

import 'ant-design-vue/dist/antd.css';

Vue.use(Antd);
Vue.use(Button);

Vue.config.productionTip = false

message.config({
  duration: 2,// 持续时间
  maxCount: 1, // 最大显示数, 超过限制时，最早的消息会被自动关闭
  top: '100px',
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
